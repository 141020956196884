/* eslint-disable */

import React from "react";
import NomadLogo from "../../assets/images/nomad-logo-horizontal.png";
import AriesLogo from "../../assets/images/Springboard_2.0_4c.png";
import BuildingIcon from "../../assets/images/buildings.png";
import BgCover from "../../assets/images/hero-bg.jpg";
import { TempHousing } from "../../components/TempHousing/TempHousing";
import { AboutNomad } from "../../components/AboutNomad/AboutNomad";
import { Faq } from "../../components/Faq/Faq";
import { ContactUs } from "../../components/ContactUs/ContactUs.js";
import { PhotoGallery2 } from "../../components/PhotoGallery/PhotoGallery2.js";
import { ComingSoon } from "../../components/ComingSoon/ComingSoon";
import { PaymentAndTerms } from "../../components/PaymentAndTerms/PaymentAndTerms";
import { EconomyAlternative } from "../../components/EconomyAlternative/EconomyAlternative";
import { RantalRates } from "../../components/RentalRates/RentalRates";
import { xhr } from '../../config/axiosConfig';
export interface HomeProps {
    readOnly? : boolean;
}

export interface HomeState {
    tabs: Array<any>;
    activeTab: number;
}

export class Home extends React.Component<HomeProps, HomeState> {

    public state:HomeState = {
        tabs: [],
        activeTab: 0
    }

    public componentDidMount():void {
        const tabs = [];
        //tabs.push(`What is Temporary Housing?`,`About nomad`, `FAQ`, `Contact Us`)
        tabs.push(`What is Temporary Housing?`,`About nomad`,`Rental Rates`, `Photo Gallery`, `Economy Alternative`, `Payment & Terms`, `FAQ`, `Contact Us`);
        xhr.post(`/airesspringboardlog`)
                .then(response => {
                    //console.log(response);
                })
                .catch(error => console.log(error));
        
        this.setState({tabs});
    }
    // eslint:disable 
    public render(): React.ReactNode {
         return (
            <>
            <div id="brand" className="wrap" >
                <div className="container">
                    <div className="row">
                        <div className="col col-md-6 col-sm-6 col-xs-6">
                            <img  className="nomad-logo pull-right"  src={AriesLogo} alt="aires logo" />
                        </div>
                        <div className="col col-md-6 col-sm-6 col-xs-6">
                            <img  className="nomad-logo pull-right"  src={NomadLogo} alt="nomad_logo" />
                        </div>
                    </div>
                </div>
            </div>
            <div id="hero" className="wrap">
                <div className="bg-cover" style ={ {backgroundImage: `url(${BgCover})` }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col col-md-12">
                        <h1>Welcome to <br /><b>Nomad Temporary Housing</b> for <b>Aires Springboard</b></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div id="header" className="wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 col-sm-2 col-xs-12 icon-graphic">
                        <img id="building-icon" src={BuildingIcon} alt="building-icon"/>
                            

                        </div>
                        <div className="col-md-10 col-sm-10 col-xs-12">
                            <div >
                                <h2>Short Term rental Housing</h2>
                                <p>
                                    Nomad Temporary Housing provides fully furnished and accessorized temporary apartments for 30 days or longer, 
                                    in major markets around the world.  Whether you are travelling alone, or with your family, Nomad has you covered 
                                    with one bedroom, two bedroom, and three bedroom floor plans.  This is turn key living at its best:  each unit 
                                    includes furniture, housewares, linens, dishes, cable or streaming TV, WiFi, and all utilities.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="nav" className="wrap">
                <div className="container">
                    <div className="row">
                        <div className="col col-md-12 col-xs-12">
                            <ul className="nav nav-pills" role="tablist">
                               {this.renderTabs()}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                <div id="body" className="wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-sm-8 col-xs-12 tabs-ui">
                                {this.renderBody()}
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <div className="bg-color sidebar-box">
                                    <a href="https://nomadtemphousing.com" target="blank" className="btn btn-warning">Visit Our Website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div id="footer" className="wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    protected handleTabClicked = ( activeTab: number ): any =>
     (event: React.MouseEvent): void  => {
        console.log("handleTabClicked activeTab", activeTab);
        this.setState({activeTab});
    }
    
    protected renderTabs = (): React.ReactNode => {
        return !!this.state.tabs && this.state.tabs.map((item, index) => {
            const className_attribute = ( this.state.activeTab === index ) ? "active" : "";
            return <li key={index} role="presentation" className={className_attribute}><a onClick={this.handleTabClicked(index)} role="tab" data-toggle="tab">{item}</a></li>
            }
        );
    }

    protected renderBody = (): React.ReactNode => {
        switch ( this.state.activeTab ) {
            case 0: // what is Tempory Housing
                return <TempHousing />
            case 1: // About Nomad
                return <AboutNomad />
            case 2: // Rental Rates
                return <RantalRates />
            case 3: // Photo Gallery
                return <PhotoGallery2 />
            case 4: // Economy Alternative
                return <EconomyAlternative />
            case 5: // Payment ann Terms
                return <PaymentAndTerms />
            case 6: // Faq
                return <Faq />
            case 7: // Contact Us
                return <ContactUs />
        }
    }
}